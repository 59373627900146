import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchAuth } from "./redux/slices/auth";
import { fetchLinks } from "./redux/slices/links";
import { Route, Routes } from "react-router-dom";

import "./App.scss";

import LoginPage from "./Pages/Login/LoginPage";
import HomePage from "./Pages/Home/HomePage";
import RegisterPage from "./Pages/Register/RegisterPage";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuth());
    dispatch(fetchLinks());
  }, []);

  return (
    <>
      <div className="wrapper">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
