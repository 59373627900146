import "./HomePage.scss";

import { useSelector } from "react-redux";

import AdminPanel from "../../Components/AdminPanel/AdminPanel";
import Spinner from "../../Components/Spinner/Spinner";
import ConfirmAccount from "../../Components/ConfirmAccount/ConfirmAccount";
import StartPage from "../StartPage/StartPage";
import Header from "../../Components/Header/Header";

const HomePage = () => {
  const user = useSelector((store) => store.auth);

  const isAuth = user.data !== null;

  const isLoading = user.status === "loading";

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isAuth ? (
        user.data.confirm ? (
          <>
            <Header />
            <div className="main">
              <div className="content">
                <div className="user__content">
                  <AdminPanel />
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="content">
                <a
                  className="creator"
                  href="https://net-walker.com"
                  target="_blank"
                >
                  net-walker
                </a>
              </div>
            </div>
          </>
        ) : (
          <ConfirmAccount />
        )
      ) : (
        <StartPage />
      )}
    </>
  );
};

export default HomePage;
