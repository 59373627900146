import "./StartPage.scss";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StartPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div class="start-page">
        <div class="blur">
          <div class="title">Short links</div>
          <div class="discription">Контролируйте развитие вашего бизнеса</div>
          <div class="auth-data">
            <button
              type="button"
              className="btn btn-secondary login"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            <button
              type="button"
              className="btn btn-secondary register"
              onClick={() => navigate("/register")}
            >
              Register
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartPage;
